import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from '@johnlewispartnership/wtr-ingredients/ingredients/Dropdown';
import { DropdownItem } from '@johnlewispartnership/wtr-ingredients/ingredients/Dropdown/DropdownItem';
import { Checkbox } from '@johnlewispartnership/wtr-ingredients/ingredients/Checkbox';
import { Radio } from '@johnlewispartnership/wtr-ingredients/ingredients/Radio';
import { LabeledControl } from '@johnlewispartnership/wtr-ingredients/ingredients/LabeledControl';
import { FilterDropdownLabel } from 'components/Search/Filters/FilterDropdownLabel';
import { groupsPropTypes } from 'components/Search/Filters/prop-types';
import { isChecked } from 'components/Search/Filters/isChecked';
import styles from './FilterCombos.scss';
import FilterDisclaimer from '../FilterDisclaimer';

const DIETARY_AND_LIFESTYLE = 'DIETARY_AND_LIFESTYLE';

const FilterCombos = ({ groups, disabled, onChange }) => {
  const handleFilterSelect = (group, _event, menuItem) => {
    onChange(group, menuItem.dataset.itemValue);
  };

  return groups.map(group => {
    const { allowMultiple, label, name, options, value } = group;
    const accessibleLabel = (
      <FilterDropdownLabel
        allowMultiple={allowMultiple}
        label={label}
        options={options}
        value={value}
      />
    );

    return (
      <article
        className={styles.filterCombos}
        data-type="filterGroup"
        key={name}
        data-testid={name}
      >
        <Dropdown
          closeOnClick
          closeOnScroll
          disabled={disabled}
          label={accessibleLabel}
          handleItemSelect={(...args) => handleFilterSelect(group, ...args)}
          direction="bottom-start"
          value={value}
        >
          {options.map(option => {
            const checked = isChecked(value, option);
            const control = allowMultiple ? (
              <Checkbox readOnly tabIndex={-1} checked={checked} name={option.value} />
            ) : (
              <Radio readOnly tabIndex={-1} checked={checked} name={option.value} />
            );
            const role = allowMultiple ? 'checkbox' : 'radio';
            const itemLabel = `${option.text} ${option.count ? `(${option.count})` : ''}`;

            return (
              <DropdownItem
                aria-checked={checked}
                component={LabeledControl}
                control={control}
                data-item-value={option.value}
                key={option.value}
                label={itemLabel}
                role={role}
              />
            );
          })}
          {name === DIETARY_AND_LIFESTYLE && <FilterDisclaimer />}
        </Dropdown>
      </article>
    );
  });
};

FilterCombos.displayName = 'FilterCombos';

FilterCombos.propTypes = {
  groups: groupsPropTypes,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

FilterCombos.defaultProps = {
  groups: [],
  disabled: undefined,
  onChange: () => {},
};

export default FilterCombos;
