import React, { KeyboardEvent } from 'react';

import { Toggle } from '@johnlewispartnership/wtr-ingredients/ingredients/Toggle';
import type { Facet, Group, Value } from 'components/Search/Filters/types';
import classNames from 'classnames';

import styles from './OffersToggle.scss';

interface OffersToggleProps {
  disabled: boolean;
  onChange: (group: Group, value: Value) => void;
  offerGroup: Facet;
}

const OfferToggle = ({ disabled, onChange, offerGroup }: OffersToggleProps) => {
  const { applied } = offerGroup;

  const handleChange = () => {
    onChange(
      {
        name: 'OFFER_TYPE',
        allowMultiple: true,
        label: '',
        options: [],
        value: '',
      },
      'promotionflag',
    );
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleChange();
    }
  };

  return (
    <article className={styles.offerControlWrapper}>
      {/* required as import from ingredients not playing well with added label  */}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className={classNames(styles.offerText, {
          [styles.disabled]: disabled,
        })}
        htmlFor="offers-toggle"
      >
        Only offers
      </label>
      <Toggle
        id="offers-toggle"
        checked={applied}
        disabled={disabled}
        data-testid="offers_toggle"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        showHide={false}
      />
    </article>
  );
};

export default OfferToggle;
