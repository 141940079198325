import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import { groupsPropTypes } from 'components/Search/Filters/prop-types';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import EventMonitor from 'components/EventMonitor/EventMonitor';
import FilterModalPanel from 'components/Search/Filters/FilterModalPanel';
import OfferToggle from 'components/Search/Filters/OfferToggle';

import classNames from 'classnames';
import styles from './FilterModal.scss';
import FilterDisclaimer from '../FilterDisclaimer';

const FilterModal = ({
  groups,
  heading,
  disabled,
  isOpen,
  onChange,
  onClear,
  onClose,
  offerGroup,
  values,
}) => (
  <Modal
    className={styles.content}
    closeTimeoutMS={300}
    contentLabel="Selected Filters"
    isOpen={isOpen}
    onRequestClose={onClose}
    overlayClassName={styles.overlay}
    bodyOpenClassName={classNames('ReactModal__Body--open', 'mobileFiltersModal')}
  >
    <header className={styles.header}>
      <h3>{heading}</h3>
      <button type="button" className={styles.close} onClick={onClose} aria-label="close" />
    </header>
    {offerGroup && (
      <div className={styles.offersWrapper}>
        <OfferToggle disabled={disabled} onChange={onChange} offerGroup={offerGroup} />
      </div>
    )}
    <div data-test="accordion-filters" className={styles.body}>
      <FilterModalPanel groups={groups} disabled={disabled} onChange={onChange} />
      <FilterDisclaimer mobile />
    </div>
    <footer className={styles.footer} data-test="accordion-footer">
      <div className={styles.button}>
        <div className={styles.done}>
          <EventMonitor
            actionType="close"
            originComponent="FilterFormNew"
            shortDescription="close filter options"
          >
            <Button label="Apply" onClick={onClose} width="full" />
          </EventMonitor>
        </div>
        {values?.length > 0 && (
          <div className={styles.clear}>
            <EventMonitor
              actionType="reset"
              originComponent="FilterFormNew"
              shortDescription="clear filters"
            >
              <Button
                disabled={disabled}
                label="Clear all"
                theme="secondary"
                onClick={onClear}
                width="full"
              />
            </EventMonitor>
          </div>
        )}
      </div>
    </footer>
  </Modal>
);

FilterModal.displayName = 'FilterModal';

FilterModal.propTypes = {
  groups: groupsPropTypes,
  heading: PropTypes.string,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  offerGroup: PropTypes.shape({
    applied: PropTypes.bool,
    filterTag: PropTypes.shape({
      group: PropTypes.string,
      id: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
};

FilterModal.defaultProps = {
  groups: [],
  heading: 'Filter by',
  disabled: undefined,
  isOpen: undefined,
  offerGroup: undefined,
  values: [],
};

export default FilterModal;
