import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

import { getAppliedFilters } from 'redux/modules/search-and-browse/selectors';
import {
  getFilterGroups,
  getHasOffersGroup,
} from 'redux/modules/search-and-browse/selectors/get-filter-groups';

import { applyFilter } from 'redux/modules/search/actions/apply-filter';
import { clearFilters } from 'redux/modules/search/actions/clear-filters';

import FilterForm from 'components/Search/Filters/FilterForm/FilterForm';

export default compose(
  connect(
    (state, props) => ({
      appliedFilters: getAppliedFilters(state, props),
      groups: getFilterGroups(state, props),
      offerGroup: getHasOffersGroup(state),
      isLoading: state.searchAndBrowse.loading,
    }),
    {
      applyFilter,
      clearFilters,
      push,
    },
  ),
  withRouter,
)(FilterForm);
