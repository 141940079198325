import React, { useCallback, useRef } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { KEY_ENTER } from 'constants/keys';
import { mergeFilterParams } from 'utils/searchQuery';

import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import { RemoveSolid } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { groupsPropTypes } from 'components/Search/Filters/prop-types';
import FilterCombos from 'components/Search/Filters/FilterCombos';
import FiltersMobile from 'components/Search/Filters/FiltersMobile';
import { SSRMediaQuery } from 'components/SSRMediaQuery';
import { BREAKPOINTS } from 'constants/grid';
import OfferToggle from 'components/Search/Filters/OfferToggle';

import styles from 'components/Search/Filters/FilterForm/FilterForm.scss';

const FilterForm = ({
  appliedFilters,
  applyFilter,
  clearFilters,
  groups,
  offerGroup,
  isLoading,
  location,
  onChange,
  push,
  stickyFilter,
}) => {
  const filterFormRef = useRef(null);

  const filterAction = useCallback(
    (action, ...args) => {
      const { pathname, search } = location;

      if (isLoading) return;

      const filterQueryString = action(...args);
      const nextQueryString = mergeFilterParams(search, filterQueryString);

      push(`${pathname}${nextQueryString}`, { noScroll: true, refinement: true });

      onChange?.();
    },
    [isLoading, location, push, onChange],
  );

  const changeFilter = useCallback(
    ({ name, allowMultiple }, value) => {
      if (value) {
        filterAction(applyFilter, name, value, allowMultiple, stickyFilter, true);
      } else {
        filterAction(clearFilters, name);
      }
    },
    [applyFilter, clearFilters, filterAction, stickyFilter],
  );

  const clearFiltersAction = useCallback(() => {
    filterAction(clearFilters);
  }, [clearFilters, filterAction]);

  const clearFiltersActionsDesktop = useCallback(() => {
    clearFiltersAction();
    filterFormRef.current?.focus();
  }, [clearFiltersAction]);

  const clearFiltersKeyDownDesktop = useCallback(
    event => {
      const { keyCode } = event;

      if (keyCode === KEY_ENTER) {
        clearFiltersActionsDesktop();
      }
    },
    [clearFiltersActionsDesktop],
  );

  const removeFilterDesktop = useCallback(
    (group, filterId) => {
      filterAction(applyFilter, group, filterId, true);
      filterFormRef.current?.focus();
    },
    [applyFilter, filterAction],
  );

  const handleKeyPressDesktop = useCallback(
    (event, group, filterId) => {
      const { keyCode } = event;

      if (keyCode === KEY_ENTER) {
        removeFilterDesktop(group, filterId);
      }
    },
    [removeFilterDesktop],
  );

  if (!groups || !groups.length) return null;

  return (
    <section className={styles.filterForm} tabIndex={-1} ref={filterFormRef}>
      <SSRMediaQuery maxWidth={BREAKPOINTS.lg}>
        <FiltersMobile
          appliedFilters={appliedFilters}
          groups={groups}
          disabled={isLoading}
          onChange={changeFilter}
          onClear={clearFiltersAction}
          offerGroup={offerGroup}
          stickyFilter={stickyFilter}
        />
      </SSRMediaQuery>
      <SSRMediaQuery className={styles.ssrDesktopWrapper} minWidth={BREAKPOINTS.lg}>
        <FilterCombos disabled={isLoading} groups={groups} onChange={changeFilter} />
        {offerGroup && (
          <OfferToggle disabled={isLoading} onChange={changeFilter} offerGroup={offerGroup} />
        )}
        {appliedFilters.length > 0 && (
          <>
            <div className={styles.break} />
            <footer data-testid="tabs-footer" className={styles.footer}>
              {appliedFilters.map(({ group, id, text }) => (
                <TextLink
                  className={styles.clearBtn}
                  component="button"
                  data-testid={`remove-filter-${id}`}
                  disabled={isLoading}
                  key={id}
                  id={id}
                  onClick={() => removeFilterDesktop(group, id)}
                  onKeyDown={event => handleKeyPressDesktop(event, group, id)}
                  data-webviewid="back-link"
                  underline="never"
                >
                  <span className="sr-only">Clear</span> {text}
                  <span className="sr-only">filter</span>
                  <RemoveSolid aria-hidden className={styles.icon} size="small" />
                </TextLink>
              ))}
              {appliedFilters.length > 1 && (
                <TextLink
                  className={styles.clearBtn}
                  component="button"
                  data-testid="btn-clear"
                  disabled={isLoading}
                  onClick={clearFiltersActionsDesktop}
                  onKeyDown={clearFiltersKeyDownDesktop}
                  data-webviewid="back-link"
                  underline="always"
                >
                  Clear all <span className="sr-only">filters</span>
                </TextLink>
              )}
            </footer>
          </>
        )}
      </SSRMediaQuery>
    </section>
  );
};

FilterForm.propTypes = {
  appliedFilters: arrayOf(
    shape({
      id: string,
      text: string,
    }),
  ),
  applyFilter: func.isRequired,
  clearFilters: func.isRequired,
  groups: groupsPropTypes,
  offerGroup: shape({
    applied: bool,
    filterTag: shape({
      group: string,
      id: string,
      text: string,
      value: string,
    }),
  }),
  isLoading: bool,
  location: shape({
    pathname: string,
    search: string,
  }),
  onChange: func,
  push: func.isRequired,
  stickyFilter: bool,
};

FilterForm.defaultProps = {
  appliedFilters: [],
  groups: [],
  offerGroup: undefined,
  onChange: undefined,
  isLoading: false,
  location: {
    pathname: undefined,
    search: undefined,
  },
  stickyFilter: false,
};

export default FilterForm;
